export const useIsHome = () => {
  const route = useRoute()
  const { locale } = useI18n()

  const isHome = computed(() => route.path === `/${locale.value}`);
  
  return {
    isHome
  }
}

export const useBaseUrl = () => {
  const runtimeConfig = useRuntimeConfig()
  const event = useRequestEvent()
  const baseUrl = event ? runtimeConfig.public.API_SERVER_BASE_URL : runtimeConfig.public.API_CLIENT_BASE_URL
  return baseUrl
}